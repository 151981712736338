// Tooltips

$tooltip-font-size:      $font-size-sm !default;
$tooltip-max-width:      200px !default;
$tooltip-color:          $white !default;
$tooltip-bg:             $black !default;
$tooltip-border-radius:  $border-radius !default;
$tooltip-opacity:        .9 !default;
$tooltip-padding-y:      .25rem !default;
$tooltip-padding-x:      .5rem !default;
$tooltip-margin:         0 !default;

$tooltip-arrow-width:    .8rem !default;
$tooltip-arrow-height:   .4rem !default;
$tooltip-arrow-color:    $tooltip-bg !default;

// Form tooltips must come after regular tooltips
$form-feedback-tooltip-padding-y:      $tooltip-padding-y !default;
$form-feedback-tooltip-padding-x:      $tooltip-padding-x !default;
$form-feedback-tooltip-font-size:      $tooltip-font-size !default;
$form-feedback-tooltip-line-height:    $line-height-base !default;
$form-feedback-tooltip-opacity:        $tooltip-opacity !default;
$form-feedback-tooltip-border-radius:  $tooltip-border-radius !default;
