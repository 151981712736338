@mixin rtl {
  @if $enable-rtl {
    @if & {
      & {
        *[dir="rtl"] & {
          @content;
        }
      }
    }
    @else {
      *[dir="rtl"] {
        @content;
      }
    }
  }
}
