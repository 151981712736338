.jumbotron {
  padding: $jumbotron-padding ($jumbotron-padding / 2);
  margin-bottom: $jumbotron-padding;
  @include border-radius($border-radius-lg);

  @include themes($jumbotron-theme-map, $create: parent) {
    color: themes-get-value("jumbotron-color");
    background-color: themes-get-value("jumbotron-bg");
  }


  @include media-breakpoint-up(sm) {
    padding: ($jumbotron-padding * 2) $jumbotron-padding;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  @include border-radius(0);
}
