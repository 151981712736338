// Carousel

// scss-docs-start carousel
$carousel-control-color:          $white !default;
$carousel-control-width:          15% !default;
$carousel-control-opacity:        .5 !default;
$carousel-control-hover-opacity:  .9 !default;
$carousel-control-transition:     opacity .15s ease !default;

$carousel-indicator-width:            30px !default;
$carousel-indicator-height:           3px !default;
$carousel-indicator-hit-area-height:  10px !default;
$carousel-indicator-spacer:           3px !default;
$carousel-indicator-active-bg:        $white !default;
$carousel-indicator-transition:       opacity .6s ease !default;

$carousel-caption-width:  70% !default;
$carousel-caption-color:  $white !default;

$carousel-control-icon-width:  20px !default;

$carousel-control-prev-icon-bg:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' width='8' height='8' viewBox='0 0 8 8'><path d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/></svg>") !default;
$carousel-control-next-icon-bg:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' width='8' height='8' viewBox='0 0 8 8'><path d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/></svg>") !default;

$carousel-transition-duration:  .6s !default;
$carousel-transition:           transform $carousel-transition-duration ease-in-out !default; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)
// scss-docs-end carousel
