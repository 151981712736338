.c-app {
  @include theme-variant(true) {
    --#{$color}: #{$value};
  }
  @include themes($body-theme-map, $create: append) {
    color: themes-get-value("body-color");
    background-color: themes-get-value("body-bg");

    --color: #{themes-get-value("body-color")};
  }
}
