// stylelint-disable declaration-no-important

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .float#{$infix}-left  {
      @include ltr {
        float: left !important;
      }
      @include rtl {
        float: right !important;
      }
    }
    .float#{$infix}-right {
      @include ltr {
        float: right !important;
      }
      @include rtl {
        float: left !important;
      }
    }
    .float#{$infix}-none  { float: none !important; }
  }
}
