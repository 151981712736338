
// Navbar

// scss-docs-start navbar
$navbar-padding-y:  $spacer / 2 !default;
$navbar-padding-x:  $spacer !default;

$navbar-nav-link-padding-x:  .5rem !default;

$navbar-brand-font-size:  $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height:         $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
$navbar-brand-height:     $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y:  ($nav-link-height - $navbar-brand-height) / 2 !default;

$navbar-toggler-padding-y:      .25rem !default;
$navbar-toggler-padding-x:      .75rem !default;
$navbar-toggler-font-size:      $font-size-lg !default;
$navbar-toggler-border-radius:  $btn-border-radius !default;
// scss-docs-end navbar

// scss-docs-start navbar-default-theme
$navbar-dark-color:                 rgba($white, .5) !default;
$navbar-dark-hover-color:           rgba($white, .75) !default;
$navbar-dark-active-color:          $white !default;
$navbar-dark-disabled-color:        rgba($white, .25) !default;
$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-dark-toggler-border-color:  rgba($white, .1) !default;

$navbar-light-color:                 rgba($black, .5) !default;
$navbar-light-hover-color:           rgba($black, .7) !default;
$navbar-light-active-color:          rgba($black, .9) !default;
$navbar-light-disabled-color:        rgba($black, .3) !default;
$navbar-light-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-light-toggler-border-color:  rgba($black, .1) !default;

$navbar-light-brand-color:        $navbar-light-active-color !default;
$navbar-light-brand-hover-color:  $navbar-light-active-color !default;
$navbar-dark-brand-color:         $navbar-dark-active-color !default;
$navbar-dark-brand-hover-color:   $navbar-dark-active-color !default;
// scss-docs-end navbar-default-theme

$navbar-variants-map: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$navbar-variants-map: map-merge(
  (
    dark: (
      "navbar-color":                 $navbar-dark-color,
      "navbar-hover-color":           $navbar-dark-hover-color,
      "navbar-active-color":          $navbar-dark-active-color,
      "navbar-disabled-color":        $navbar-dark-disabled-color,
      "navbar-toggler-icon-bg":       $navbar-dark-toggler-icon-bg,
      "navbar-toggler-border-color":  $navbar-dark-toggler-border-color,
      "navbar-brand-color":           $navbar-dark-brand-color,
      "navbar-brand-hover-color":     $navbar-dark-brand-hover-color
    ),
    light: (
      "navbar-color":                 $navbar-light-color,
      "navbar-hover-color":           $navbar-light-hover-color,
      "navbar-active-color":          $navbar-light-active-color,
      "navbar-disabled-color":        $navbar-light-disabled-color,
      "navbar-toggler-icon-bg":       $navbar-light-toggler-icon-bg,
      "navbar-toggler-border-color":  $navbar-light-toggler-border-color,
      "navbar-brand-color":           $navbar-light-brand-color,
      "navbar-brand-hover-color":     $navbar-light-brand-hover-color
    )
  ),
  $navbar-variants-map
);

$navbar-default-variant: null !default;
$navbar-variants: (
  default:  $navbar-default-variant,
  map:      $navbar-variants-map
) !default;

$navbar-theme-map: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$navbar-theme-map: map-merge(
  (
    default:  $navbar-variants
  ),
  $navbar-theme-map
);
