// Here you can add other styles
.c-main {
  background-color: #edf1f5;
}

.c-sidebar {
  background-color: #01a768 !important;
}

.c-sidebar-nav-link:hover {
  background-color: #027348 !important;
}

.c-sidebar-minimizer {
  color: antiquewhite;
}

.c-sidebar-nav-dropdown-items {
  background-color: #028d58;
}

.c-header {
  background-color: #edf1f5;
  border-style: none;
}

.c-subheader {
  background-color: #edf1f5;
  border-top-style: none;
  border-style: none;
}

.c-footer {
  background-color: #edf1f5;
  border-style: none;
}

.tableCss {
  height: 1000px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #4caf50;
}

input:focus + .slider {
  box-shadow: 0 0 1px #4caf50;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.checkbox-container {
  padding: 15px 10px;
  border: 1px solid #cacaca;
  border-radius: 5px;
}

.left-tile {
}

.right-tile {
  color: $black;

  .small-card {
    display: flex;
    flex-direction: row;
    align-items: center;

    .small-img {
      width: 40%;
      text-align: center;

      img {
        width: 60px;
        height: auto;
        // margin-right: 10px;
      }
    }

    .small-card-detail {
      display: flex;
      flex-direction: column;
      font-size: 15px;
      line-height: 25px;

      .number {
        font-size: 25px;
        font-weight: 700;
      }
    }
  }
}

.tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  .tab {
    width: 30%;
    margin: 10px 0;

    &.custom-card {
      border-radius: 5px 5px 4px 4px !important;
      border: rgba(#1D242E, 0.3) solid 1px;
      height: 420px;

      .custom-card-header {
        background-color: #01A768;
        border-radius: 4px 4px 0 0;
        color: white;
      }

      .custom-card-body {
        padding-left: 1rem;

        .information-detail {
          display: flex;
          flex-direction: row;
          align-items: center;

          .text {
            width: 50%;
            font-weight: 600;
            line-height: 2rem;
          }
        }
      }

    }

  }
}

.card {
  border-radius: 1rem;
  border-style: none;
}

.card-header {
  border-radius: 1rem;
  border-style: none;
  //background-color: #01a768;
  //color: #fff;
  &:first-child {
    border-radius: 1rem 1rem 0 0;
    border-style: none;
  }

  padding-bottom: 0;
}

.multi-tabs {
  .card {
    border-radius: 0 0 1rem 1rem;
  }

  .nav {
    background-color: #fff;
    border-style: none;
    border-radius: 1rem 1rem 0 0;
    margin-bottom: 2px;
    justify-content: space-around;
  }

  .nav-item {
    background-color: #fff;
    border-radius: 1rem 1rem 0 0;

    .active {
      border-radius: 1rem 1rem 0 0;
      color: black;
      background-color: #fff;

      &::after {
        content: "";
        display: block;
        margin: 0 auto;
        width: 100%;
        padding-top: 5px;
        border-bottom: 2px solid #01a768;
      }
    }
  }

  .nav-link {
    color: gray;
    border-style: none;

    &:hover {
      border-style: none;
    }
  }
}

.comingsoon {
  text-align: center;
  font-size: 3rem;
}

.c-body {
  .c-main {
    padding-top: 0;
  }
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
   padding: 10px;
  color: #343434;
}

.c-sidebar .c-sidebar-nav-dropdown-toggle {
  position: relative;
  margin: 0px;
  padding: 11px;
}
.c-sidebar-nav-item {
  width: inherit;
  height: 40px;
}
.modal-body .form-control {
  display: block;
  width: 100%;
  height: calc(2.05rem + -4px);
  padding: 0px;
  padding-left: 6px;
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1;
  background-clip: padding-box;
  border: 1.5px solid;
  color: #768192;
  background-color: #fff;
  border-color: #d8dbe0;
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-top: -5px;
}
.modal-body .custom-select {
  display: inline-block;
  height: calc(2.15rem - 5px);
  font-size: .875rem;
  line-height: 1;
  vertical-align: middle;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #d8dbe0;
  margin-top: -5px;
  border-radius: 3px;
}

.modal-body .form-group {
  margin-bottom: 0.2rem;
}
.btn-pill {
  border-radius: 3px;
}
.card {
  border-radius: 3px;
  border-style: none;
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0px;
  color: #343434;
}
.printButton{
  color: black;
  background-color: #d3d3d3;

  &:hover {
    background-color: #01A768;
    color: white;
  }
}


.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid;
  border-bottom-color: #d8dbe0;
  background: lightgrey;
}
.c-header .c-subheader {
  margin-top: -7px;
  border-top: 1px solid #d8dbe0;
}
.rmdp-container .rmdp-input {
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  width: 336px;
  max-height: 28px;
  color: #768192;
}
.modal-body .searchWrapper {
  border: 1px solid #ccc;
  border-radius: 3px;
  min-height: 18px;
  max-height: 32px;
  padding: 5px;
  position: relative;
}
.btn i, .btn .c-icon {
  height: 0.875rem;
  margin: 0.21875rem 0;
  font-weight: bold;
}
.rmdp-container .rmdp-input {
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  width: 336px;
  max-height: 29px;
  color: #768192;
}
.search-group{
  .rmdp-container .rmdp-input {
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    width: 240px;
    max-height: 35px;
    color: #768192;
  }
}

.rmdp-container .rmdp-input {
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  width: 240px;
  max-height: 28px;
  color: #768192;
}
.treatment-group{
  .rmdp-container .rmdp-input {
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    width: 353px;
    max-height: 28px;
    color: #768192;
  }
}