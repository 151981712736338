//
// RTL Support
//
@include rtl {
  direction: rtl;
  unicode-bidi: embed;

  body {
    text-align: right;
  }
}
