@mixin avatar($width, $status-width) {
  width: $width;
  height: $width;
  font-size: $width / 2.5;

  .c-avatar-status {
    width: $status-width;
    height: $status-width;
  }
}
