// Spinners
// scss-docs-start spinner
$spinner-width:         2rem !default;
$spinner-height:        $spinner-width !default;
$spinner-border-width:  .25em !default;

$spinner-width-sm:         1rem !default;
$spinner-height-sm:        $spinner-width-sm !default;
$spinner-border-width-sm:  .2em !default;
// scss-docs-end spinner
