// Avatars
// scss-docs-start avatars
$avatar-sm-width:         24px !default;
$avatar-sm-status-width:  8px !default;

$avatar-width:            36px !default;
$avatar-status-width:     10px !default;

$avatar-lg-width:         48px !default;
$avatar-lg-status-width:  12px !default;

$avatar-xl-width:         64px !default;
$avatar-xl-status-width:  14px !default;
// scss-docs-end avatars
