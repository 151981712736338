// stylelint-disable property-blacklist, declaration-no-important

//
// Border
//

@include themes($border-theme-map) {
  .border         { border: $border-width solid themes-get-value("border-color") !important; }
  .border-top     { border-top: $border-width solid themes-get-value("border-color") !important; }
  .border-right   { border-right: $border-width solid themes-get-value("border-color") !important; }
  .border-bottom  { border-bottom: $border-width solid themes-get-value("border-color") !important; }
  .border-left    { border-left: $border-width solid themes-get-value("border-color") !important; }
}

.border-0        { border: 0 !important; }
.border-top-0    { border-top: 0 !important; }
.border-right-0  { border-right: 0 !important; }
.border-bottom-0 { border-bottom: 0 !important; }
.border-left-0   { border-left: 0 !important; }

@include theme-variant() {
  .border-#{$color} {
    border: 1px solid !important;
    border-color: $value !important;
  }
}

.border-white {
  border-color: $white !important;
}

//
// Border-radius
//

.rounded-sm {
  border-radius: $border-radius-sm !important;
}

.rounded {
  border-radius: $border-radius !important;
}

.rounded-top {
  border-top-left-radius: $border-radius !important;
  border-top-right-radius: $border-radius !important;
}

.rounded-right {
  border-top-right-radius: $border-radius !important;
  border-bottom-right-radius: $border-radius !important;
}

.rounded-bottom {
  border-bottom-right-radius: $border-radius !important;
  border-bottom-left-radius: $border-radius !important;
}

.rounded-left {
  border-top-left-radius: $border-radius !important;
  border-bottom-left-radius: $border-radius !important;
}

.rounded-lg {
  border-radius: $border-radius-lg !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: $rounded-pill !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

@each $prop, $abbrev in (border: b) {
  @each $size in (0,1,2) {
    @if $size == 0 {
      .#{$abbrev}-a-#{$size} { #{$prop}: 0 !important; } // a = All sides
      .#{$abbrev}-t-#{$size} { #{$prop}-top: 0 !important; }
      .#{$abbrev}-r-#{$size} { #{$prop}-right: 0 !important; }
      .#{$abbrev}-b-#{$size} { #{$prop}-bottom: 0 !important; }
      .#{$abbrev}-l-#{$size} { #{$prop}-left: 0 !important; }
    } @else {
      .#{$abbrev}-a-#{$size} { #{$prop}: $size * $border-width solid $border-color; } // a = All sides
      .#{$abbrev}-t-#{$size} { #{$prop}-top: $size * $border-width solid $border-color; }
      .#{$abbrev}-r-#{$size} { #{$prop}-right: $size * $border-width solid $border-color; }
      .#{$abbrev}-b-#{$size} { #{$prop}-bottom: $size * $border-width solid $border-color; }
      .#{$abbrev}-l-#{$size} { #{$prop}-left: $size * $border-width solid $border-color; }
    }
  }
}
